<template>
  <div id="financial_center">
    <div class="tabbar">
      <div :class="item.path===selectItem?'isAcitve':''" v-for="(item,index) in tabbarItem" :key="index" @click="clickItem(item,index)">
        {{item.label}}
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      selectItem:this.$route.path,
      tabbarItem:[
        {label:'充值',path:'/personalCenter/financialCenter'},
        {label:'提现',path:'/personalCenter/financialCenter/withdraw'},
        {label:'提现记录',path:'/personalCenter/financialCenter/withdrawRecord'},
        {label:'收支明细',path:'/personalCenter/financialCenter/moneyDetails'},
      ],
    }
  },
  methods: {
    clickItem(item, index) {
      if(this.$route.path !== item.path){
        this.selectItem = item.label
        this.$router.push({
          path:item.path
        });
      }

    },
  },
  mounted () {

  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='less' scoped>
#financial_center{
  padding: 0 22px;
  display: flex;
  flex-direction: column;
  .tabbar{
    margin-top: 22px;
    height: 58px;
    background: #F4F4F4;
    border-radius: 10px;
    padding: 0 71px;
    display: flex;
    flex-direction: row;
    align-items: center;
    div{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #5B5B5B;
      margin-right: 83px;
      cursor: pointer;
    }
    .isAcitve{
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 500;
      color: #5B5B5B;
      padding-bottom: 6px;
      background: url("../../../assets/image/PersonalCenter/MyCollection/selectItem.png") no-repeat;
      background-position:bottom;
    }
    div:last-of-type{
      margin: 0;
    }
  }
}
</style>
